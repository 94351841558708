import axios from 'axios'
import store from '../../store/index.js'
import Qs from 'qs'
let baseUrl
let timer = null
let loading = null
process.env.NODE_ENV === 'development'? baseUrl= "http://192.168.3.40:8080/" : baseUrl = "http://183.63.139.50:18088/"
//baseUrl = "http://121.32.135.122:8096/"

//报错控制
const error = (msg,wait=2000)=>{
    if(timer!==null){
        clearTimeout(timer)
        timer = setTimeout(()=>{iview.Message.error({content:msg})},wait)
    }else{
        iview.Message.error({content:msg})
    }
}

http://192.168.3.80:8080/#/site
// axios 配置
axios.defaults.timeout = 30000
axios.defaults.baseURL = baseUrl
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'

// http request 拦截器
axios.interceptors.request.use(
  config => {
	if(!loading&&config.loading) loading = iview.Spin.show()
	else{
		iview.Spin.hide()
		config.loading?loading = iview.Spin.show():''
	}
	config.headers.Authorization = store.state.token||''
    return config
  },
  err => {
	console.log(err)
    return Promise.reject(err)
  },
)

// http response 拦截器
axios.interceptors.response.use(
  response => {
	iview.Spin.hide()
	if(response.data.code=='0000'||response.data.code==200){
		return response.data
	}else{
		response.data.message?error(response.data.message):''
		return false
	}
  },
  err => {
	iview.Spin.hide()
	error('与服务器断开连接，请稍后再试')
	console.log(err)
    // return Promise.reject(err)
  }
)
export const post = (data)=>{
	return axios({
		url:data.url,
		method:'post',
		data:Qs.stringify(data.data),
		loading:data.loading==undefined?true:data.loading
	})
}
export const postStr = (data)=>{
	return axios({
		url:data.url,
		method:'post',
		headers:{'Content-Type':'application/json'},
		data:data.data
	})
}
export const get = (data)=>{
	return axios({
		url:data.url,
		params:data.data,
		method:'get',
		loading:data.loading==undefined?true:data.loading
	})
}
export const del = (data)=>{
	return axios({
		url:data.url,
		params:data.data,
		method:'delete'
	})
}