<template>
	<div class="home">
		<div class="homeTitle">
			<Carousel v-model="value1" arrow="never" :radius-dot="true" autoplay :autoplay-speed="4000">
				<CarouselItem>
					<div class="demo-carousel">
						<img src="../../assets/img/home/banner.png" alt="">
					</div>
					<!-- <div class="public theme">
						<img src="../../assets/img/home/主题3.png" alt="">
					</div> -->
				</CarouselItem>
				<CarouselItem>
					<div class="demo-carousel">
						<img src="../../assets/img/home/banner2.png" alt="">
					</div>
					<!-- <div class="public theme">
						<img src="../../assets/img/home/主题(1).png" alt="">
					</div> -->
				</CarouselItem>
				<CarouselItem>
					<div class="demo-carousel">
						<img src="../../assets/img/home/banner3.png" alt="">
					</div>
					<!-- <div class="public theme">
						<img src="../../assets/img/home/主题.png" alt="">
					</div> -->
				</CarouselItem>
			</Carousel>
			<div class="public homeLink">
				<img src="../../assets/img/home/logo1.png" alt="">
			</div>
			<div class="public line">
				<img src="../../assets/img/home/隔线.png" alt="">
			</div>
			<div class="switch">
				<img src="../../assets/img/home/点击条.png" alt="">
			</div>
			<div class="public module">
				<router-link :to="{name:'Home'}">
					<p @mouseover="init(1)" @mouseout="out">首页</p>
				</router-link>
				<router-link :to="{name:'products'}">
					<p @mouseover="init(2)" @mouseout="out">产品与服务</p>
				</router-link>
				<router-link :to="{name:'news'}">
					<p @mouseover="init(3)" @mouseout="out">新闻资讯</p>
				</router-link>
				<router-link :to="{name:'About'}">
					<p @mouseover="init(4)" @mouseout="out">关于旭普</p>
				</router-link>
				<p @mouseover="init(5)" @mouseout="out" @click="openWindow">招聘信息</p>
			</div>
			<div class="public ViewMore">
				<router-link :to="{name:'products'}">
					<p>VIEW MORE <img src="../../assets/img/home/微信图片_20201207171801.png" alt=""></p>
				</router-link>
			</div>
			<!-- <p class="public scroll">滚动</p>
			<div class="public down">
				<img src="../../assets/img/home/slid.png" alt="">
			</div> -->
		</div>
		<div class="about">
			<div class="aboutFont">
				<h3 class="aboutTitle">关于旭普</h3>
				<div class="subTitle">
					<img src="../../assets/img/home/椭圆 3.png" alt="">
					<h4>ABOUT SUPERSIT</h4>
				</div>
				<P class="font1">
					旭普集团（以下简称“集团”）是依托武汉大学空间信息技术与数字工程中心，致力于从事3S（GIS、RS、GNSS）信息技术创新，提供地理空间信息云计算、云存储、云服务卓越产品及其应用的解决方案集成商。
				</P>
				<p class="font1">
					旭日东升、朝阳普照。2002年，集团发端于羊城广州，现已形成以广东旭普空间信息技术产业发展有限公司、四川旭普信息产业发展有限公司、北斗旭普空间信息产业（武汉）有限公司为核心的集团发展布局。</p>
				<p class="font1">
					科技以人为本，集团聚集了一大批有志于3S技术创新的工程应用、云计算研究及致力于推动我国空间信息产业发展的优秀人才，行业专家、博士、硕士以及高级工程技术与管理人员的比例超过了30%。</p>
				<div class="ViewMore">
					<router-link :to="{name:'About'}">
						<p>VIEW MORE <img src="../../assets/img/home/微信图片_20201207171801.png" alt=""></p>
					</router-link>
				</div>
			</div>
			<div class="aboutPic1">
				<img src="../../assets/img/home/组 361.png" alt="">
			</div>
		</div>
		<div class="aboutPic2">
			<div class="leftAbout">
				<div class="topAbout">
					<div class="firstTopAbout" style="padding-left: 10px;">
						<p>广东旭普空间信息技术</p>
						<p>产业发展有限公司成立</p>
					</div>
					<div class="firstTopAbout bigFont">
						<p>2012</p>
					</div>
					<div class="firstTopAbout">
						<p>广州凡达生态</p>
						<p>环保院成立</p>
					</div>
					<div class="firstTopAbout bigFont">
						<p>至今/未来</p>
					</div>
				</div>
				<div class="topAbout bottomAbout">
					<div class="firstTopAbout bigFont" style="padding-left: 10px;">
						<p>2002</p>
					</div>
					<div class="firstTopAbout">
						<p>北斗旭普空间信息技术</p>
						<p>产业发展有限公司成立</p>
					</div>
					<div class="firstTopAbout bigFont">
						<p>2014</p>
					</div>
					<div class="firstTopAbout">
						<p>集团化格局</p>
						<p>蓄力前行</p>
					</div>
				</div>
			</div>
			<div class="rightAbout">
				<p>旭普成立至今已有</p>
				<p>
					19years
					<router-link :to="{name:'About'}">
						<img src="../../assets/img/home/tuxing.png" alt="">
					</router-link>
				</p>
			</div>
		</div>
		<div class="solution">
			<div class="solutionTI">
				<div class="solutionTitle">
					<h3 class="aboutTitle">旭普解决方法</h3>
					<div class="subTitle">
						<img src="../../assets/img/home/椭圆 3.png" alt="">
						<h4>SUPERSIT SOLUTION</h4>
					</div>
				</div>
				<div class="switchLine">
					<span class="zeroOne">01</span>
					<span class="zeroSix">/07</span>
					<div class="Aline">
						<div class="Sline"></div>
					</div>
				</div>
			</div>
			<div class="solutionPic">
				<Carousel autoplay :autoplay-speed="4000" v-model="value2" arrow="always" dots="none"
					@on-change="changeNum">
					<CarouselItem v-for="(item,index) in productsList" :key="index">
						<div class="demo-carousel">
							<img :src="item.carouselPic" alt="">
						</div>
						<div class="smartSpace">
							<h2>{{item.title}}</h2>
							<p>{{item.subtitle}}</p>
							<div style="width: 1.11458333rem;">
								<router-link :to="{name:'products',query:{id:item.id,toDetail:true}}">
									<div class="button">查看解决方案详情 <img src="../../assets/img/home/20201207171722.png" alt=""></div>
								</router-link>
							</div>
						</div>
					</CarouselItem>
					<!-- <CarouselItem>
						<div class="demo-carousel">
							<img src="../../assets/img/home/智慧安防banner(2).png" alt="">
						</div>
						<div class="smartSpace">
							<h2>智慧时空+农业</h2>
							<p>SMART SPACE TIME + AGRICULTURE</p>
							<div style="width: 1.11458333rem;">
								<router-link :to="{name:'products',query:{id:17,toDetail:true}}">
									<div class="button">查看解决方案详情 <img src="../../assets/img/home/20201207171722.png"
											alt=""></div>
								</router-link>
							</div>
						</div>
					</CarouselItem>
					<CarouselItem>
						<div class="demo-carousel">
							<img src="../../assets/img/home/智慧安防banner(3).png" alt="">
						</div>
						<div class="smartSpace">
							<h2>智慧时空+自然资源</h2>
							<p>SMART SPACE TIME + LAND</p>
							<div style="width: 1.11458333rem;">
								<router-link :to="{name:'products',query:{id:16,toDetail:true}}">
									<div class="button">查看解决方案详情 <img src="../../assets/img/home/20201207171722.png"
											alt=""></div>
								</router-link>
							</div>
						</div>
					</CarouselItem>
					<CarouselItem>
						<div class="demo-carousel">
							<img src="../../assets/img/home/智慧安防banner (2).png" alt="">
						</div>
						<div class="smartSpace">
							<h2>智慧时空+档案</h2>
							<p>SMART SPACE TIME + ARCHIVES</p>
							<div style="width: 1.11458333rem;">
								<router-link :to="{name:'products',query:{id:15,toDetail:true}}">
									<div class="button">查看解决方案详情 <img src="../../assets/img/home/20201207171722.png"
											alt=""></div>
								</router-link>
							</div>
						</div>
					</CarouselItem>
					<CarouselItem>
						<div class="demo-carousel">
							<img src="../../assets/img/home/智慧安防banner.png" alt="">
						</div>
						<div class="smartSpace">
							<h2>智慧时空+园区</h2>
							<p>SMART SPACE TIME + PARK</p>
							<div style="width: 1.11458333rem;">
								<router-link :to="{name:'products',query:{id:14,toDetail:true}}">
									<div class="button">查看解决方案详情 <img src="../../assets/img/home/20201207171722.png"
											alt=""></div>
								</router-link>
							</div>
						</div>
					</CarouselItem>
					<CarouselItem>
						<div class="demo-carousel">
							<img src="../../assets/img/home/智慧安防banner(4).png" alt="">
						</div>
						<div class="smartSpace">
							<h2>智慧时空+办公</h2>
							<p>SMART SPACE TIME + OFFICE</p>
							<div style="width: 1.11458333rem;">
								<router-link :to="{name:'products',query:{id:13,toDetail:true}}">
									<div class="button">查看解决方案详情 <img src="../../assets/img/home/20201207171722.png"
											alt=""></div>
								</router-link>
							</div>
						</div>
					</CarouselItem>
					<CarouselItem>
						<div class="demo-carousel">
							<img src="../../assets/img/home/智慧安防banner(5).png" alt="">
						</div>
						<div class="smartSpace">
							<h2>智慧时空+物联</h2>
							<p>SMART SPACE TIME + IOT</p>
							<div style="width: 1.11458333rem;">
								<router-link :to="{name:'products',query:{id:12,toDetail:true}}">
									<div class="button">查看解决方案详情 <img src="../../assets/img/home/20201207171722.png"
											alt=""></div>
								</router-link>
							</div>
						</div>
					</CarouselItem> -->
				</Carousel>
			</div>
			<div class="programme">
				<div class="planList">
					<router-link v-for="(item,index) in productsList" :key="index" :to="{name:'products',query:{id:item.id,toDetail:true}}">
						<div class="plans">
							<div>
								<img src="../../assets/img/home/椭圆 3(1).png" alt="">
								<span>“{{item.title}}”解决方案</span>
							</div>
							<p>{{item.brief}}</p>
							<p>Guang Zhou</p>
						</div>
					</router-link>
					<!-- <router-link :to="{name:'products',query:{newsTitle:'农业',newsId:2,toDetail:true}}">
						<div class="plans">
							<div>
								<img src="../../assets/img/home/椭圆 3(1).png" alt="">
								<span>“智慧时空+农业”解决方案</span>
							</div>
							<p>助力集约化农业、现代农业、精准农业历史进程</p>
							<p>Guang Zhou</p>
						</div>
					</router-link>
					<router-link :to="{name:'products',query:{newsTitle:'自然资源',newsId:3,toDetail:true}}">
						<div class="plans">
							<div>
								<img src="../../assets/img/home/椭圆 3(1).png" alt="">
								<span>“智慧时空+自然资源”解决方案</span>
							</div>
							<p>实践海陆空资源状况“一览无余”，资源家底“心中有数”</p>
							<p>Guang Zhou</p>
						</div>
					</router-link>
					<router-link :to="{name:'products',query:{newsTitle:'档案',newsId:4,toDetail:true}}">
						<div class="plans">
							<div>
								<img src="../../assets/img/home/椭圆 3(1).png" alt="">
								<span>“智慧时空+档案”解决方案</span>
							</div>
							<p>实现档案价值的深层次开发利用及档案资源的高度智能化管理</p>
							<p>Guang Zhou</p>
						</div>
					</router-link>
					<router-link :to="{name:'products',query:{newsTitle:'园区',newsId:5,toDetail:true}}">
						<div class="plans">
							<div>
								<img src="../../assets/img/home/椭圆 3(1).png" alt="">
								<span>“智慧时空+园区”解决方案</span>
							</div>
							<p>规范化园区管理，加速企业孵化、创业创新创造过程</p>
							<p>Guang Zhou</p>
						</div>
					</router-link>
					<router-link :to="{name:'products',query:{newsTitle:'办公',newsId:6,toDetail:true}}">
						<div class="plans">
							<div>
								<img src="../../assets/img/home/椭圆 3(1).png" alt="">
								<span>“智慧时空+办公”解决方案</span>
							</div>
							<p>提升企业管理效率，促进释放经营成效</p>
							<p>Guang Zhou</p>
						</div>
					</router-link>
					<router-link :to="{name:'products',query:{newsTitle:'物联',newsId:7,toDetail:true}}">
						<div class="plans">
							<div>
								<img src="../../assets/img/home/椭圆 3(1).png" alt="">
								<span>“智慧时空+物联”解决方案</span>
							</div>
							<p>远程监控、设置调整、系统升级、故障排查等全生命周期管理</p>
							<p>Guang Zhou</p>
						</div>
					</router-link> -->
				</div>
			</div>
			<div class="soluctionButton">
				<div class="Sbutton up" @click="up">
					<Icon type="ios-arrow-up" />
				</div>
				<div class="Sbutton down1" @click="down">
					<Icon type="ios-arrow-down" />
				</div>
			</div>
		</div>
		<div class="newsView">
			<div class="newsTitle">
				<div>
					<h3 class="aboutTitle">新闻资讯</h3>
					<div class="subTitle">
						<img src="../../assets/img/home/椭圆 3.png" alt="">
						<h4>NEWS AND INFORMATION</h4>
					</div>
				</div>
				<div class="newsCh">
					<div class="newsBUtton culture" @click="companyNews('culture')" @mouseover="mousein('culture')"
						@mouseout="mouseou('culture')">公司文化</div>
					<div class="newsBUtton companyNews" @click="companyNews('news')" @mouseover="mousein('news')"
						@mouseout="mouseou('news')">公司新闻</div>
					<router-link :to="{name:'news'}">
						<div class="newsBUtton">MORE</div>
					</router-link>
				</div>
			</div>
			<div class="newsList" style="height: 1.453125rem;" v-show="!isnews">
				<Carousel v-model="value3" arrow="never" dots="none" autoplay :autoplay-speed="4000">
					<CarouselItem>
						<div class="newsPic1">
							<img src="../../assets/img/home/组 362.png" alt="">
						</div>
					</CarouselItem>
					<CarouselItem>
						<div class="newsPic1">
							<img src="../../assets/img/home/组 362 拷贝 2.png" alt="">
						</div>
					</CarouselItem>
					<CarouselItem>
						<div class="newsPic1">
							<img src="../../assets/img/home/组 362 拷贝.png" alt="">
						</div>
					</CarouselItem>
				</Carousel>
				<div class="newsSimper">
					<div class="singleNews" v-for="(item,index) in newsList" :key="index">
						<img src="../../assets/img/home/椭圆 2 拷贝 3.png" alt="">
						<router-link
							:to="{name:'news',query:{title:item.title,cid:item.cid,toDetail:true,isCulture:true}}">
							<p>{{item.title}}</p>
						</router-link>
						<span>{{item.shortTime}}</span>
					</div>
					<div style="width: 0.5rem; height: 0.53125rem;"></div>
					<router-link :to="{name:'news'}">
						<div class="readMore">READ MORE <img src="../../assets/img/home/jiantou 拷贝 3.png" alt=""></div>
					</router-link>
				</div>
			</div>
			<div class="newsList" style="margin: 0;" v-show="!isnews">
				<Carousel v-model="value3" arrow="never" :radius-dot="true" autoplay :autoplay-speed="4000">
					<CarouselItem v-for="(item,index) in newsList" :key="index">
						<div class="firstNews">
							<div class="FNTitle">
								<h2>{{item.month}}/{{item.day}}</h2>
								<p>{{item.year}}</p>
							</div>
							<h4 style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">{{item.title}}</h4>
							<p style="overflow: hidden; text-overflow: ellipsis;display:-webkit-box; -webkit-box-orient:vertical; -webkit-line-clamp:2;">{{item.summary}}</p>
							<router-link :to="{name:'news',query:{title:item.title,cid:item.cid,toDetail:true,isCulture:true}}">
								<div class="readMore">READ MORE <img src="../../assets/img/home/jiantou 拷贝 3.png" alt=""></div>
							</router-link>
						</div>
					</CarouselItem>
				</Carousel>
				<router-link :to="{name:'news',query:{title:newsList.length != 0 ? newsList[0].title : '',cid:newsList.length != 0 ? newsList[0].cid : '',toDetail:true,isCulture:true}}">
					<div class="newsPic2">
						<img src="../../assets/img/home/组 363.png" alt="">
					</div>
					<p class="pic2Font">{{newsList.length != 0 ? newsList[0].title : ""}}</p>
				</router-link>
			</div>
			<div class="newsList" style="height: 1.453125rem;" v-show="isnews">
				<Carousel v-model="value3" arrow="never" dots="none" autoplay :autoplay-speed="4000">
					<CarouselItem>
						<div class="newsPic1">
							<img src="../../assets/img/home/组 362 拷贝 3.png" alt="">
						</div>
					</CarouselItem>
					<CarouselItem>
						<div class="newsPic1">
							<img src="../../assets/img/home/组 362 拷贝 3(1).png" alt="">
						</div>
					</CarouselItem>
					<CarouselItem>
						<div class="newsPic1">
							<img src="../../assets/img/home/组 362 拷贝 3(2).png" alt="">
						</div>
					</CarouselItem>
				</Carousel>
				<div class="newsSimper">
					<div class="singleNews" v-for="(item,index) in newsList" :key="index">
						<img src="../../assets/img/home/椭圆 2 拷贝 3.png" alt="">
						<router-link :to="{name:'news',query:{title:item.title,cid:item.cid,toDetail:true,isCulture:false}}">
							<p>{{item.title}}</p>
						</router-link>
						<span>{{item.shortTime}}</span>
					</div>
					<div style="width: 0.5rem; height: 0.40625rem;"></div>
					<router-link :to="{name:'news'}">
						<div class="readMore">READ MORE <img src="../../assets/img/home/jiantou 拷贝 3.png" alt=""></div>
					</router-link>
				</div>
			</div>
			<div class="newsList" style="margin: 0;" v-show="isnews">
				<Carousel v-model="value3" arrow="never" :radius-dot="true" autoplay :autoplay-speed="4000">
					<CarouselItem v-for="(item,index) in newsList" :key="index">
						<div class="firstNews">
							<div class="FNTitle">
								<h2>{{item.month}}/{{item.day}}</h2>
								<p>{{item.year}}</p>
							</div>
							<h4 style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">{{item.title}}</h4>
							<p style="overflow: hidden; text-overflow: ellipsis;display:-webkit-box; -webkit-box-orient:vertical; -webkit-line-clamp:2;">{{item.summary}}</p>
							<router-link :to="{name:'news',query:{title:item.title,cid:item.cid,toDetail:true,isCulture:false}}">
								<div class="readMore">READ MORE <img src="../../assets/img/home/jiantou 拷贝 3.png" alt=""></div>
							</router-link>
						</div>
					</CarouselItem>
				</Carousel>
				<router-link :to="{name:'news',query:{title:newsList.length != 0 ? newsList[0].title : '',cid:newsList.length != 0 ? newsList[0].cid : '',toDetail:true,isCulture:false}}">
					<div class="newsPic2">
						<img src="../../assets/img/home/组 363.png" alt="">
					</div>
					<p class="pic2Font">{{newsList.length != 0 ? newsList[0].title : ""}}</p>
				</router-link>
			</div>
		</div>
		<div class="cooperation">
			<div class="cooTitle">
				<p>携手企业</p>
				<p>合作共赢</p>
			</div>
			<div class="cooFont">
				<p>科技以人为本，集团聚集了一大批有志于3S技术创新的工程应用、云计算研究及致力于推动我国空间信息产业发展的优秀人才，行业专家、博士、硕士以及高级工程技术与管理人员的比例超过了30%。</p>
				<div class="cooName">
					<img src="../../assets/img/home/矢量智能对象.png" alt="">
					<img src="../../assets/img/home/矢量智能对象(1).png" alt="">
					<img src="../../assets/img/home/矢量智能对象(2).png" alt="">
					<img src="../../assets/img/home/矢量智能对象(4).png" alt="">
					<img src="../../assets/img/home/矢量智能对象(5).png" alt="">
					<img src="../../assets/img/home/矢量智能对象(3).png" alt="">
				</div>
			</div>
		</div>
		<recruit v-show="showRecruit" @close="closeWindow"></recruit>
		<bottom @open="openWindow"></bottom>
	</div>
</template>

<script>
	import api from '../../services/api.js'
	import bottom from "./bottom.vue"
	import recruit from "../recruitmentInformation/recruit.vue"
	// @ is an alias to /src
	export default {
		name: 'Home',
		data() {
			return {
				value1: 0,
				value2: 0,
				value3: 0,
				startTime: null,
				timer: null,
				toRight: false,
				transNum: 0,
				isnews: false,
				showRecruit: false,
				newsList: [],
				productsList: [],
			}
		},
		components: {
			bottom,
			recruit
		},
		mounted() {
			this.getNewsList()
			this.getProductsList()
		},
		methods: {
			getProductsList() {
				let prams = {
					type: 5,
					curPage: 1,
					pageSize: 10
				}
				this.$axios({
					url: api.productsList,
					method: 'post',
					params: prams
				}).then((data) => {
					data.data.forEach((item,index)=>{
						item.carouselPic = api.url + item.carouselPic
					})
					this.productsList = data.data
				})
			},
			getNewsList() {
				this.newsList = []
				let prams = {}
				if (this.isnews == false) {
					prams = {
						type: 'culture',
						curPage: 1,
						pageSize: 5
					}
				} else {
					prams = {
						type: 'news',
						curPage: 1,
						pageSize: 5
					}
				}
				this.$axios({
					url: api.newsList,
					method: 'post',
					params: prams
				}).then((data) => {
					data.data.forEach((item, index) => {
						let shortTime = ""
						let year = ""
						let month = ""
						let day = ""
						item.pic = api.url + item.pic
						shortTime = item.createTime.slice(5,10)
						year = item.createTime.slice(0,4)
						month = item.createTime.slice(5,7)
						day = item.createTime.slice(8,10)
						item["shortTime"] = shortTime
						item["year"] = year
						item["month"] = month
						item["day"] = day
						this.newsList.push(item)
					})
				})
			},
			init(id) {
				var switchBar = document.querySelector(".switch")
				if (id == 1) {
					switchBar.style.cssText = "left: 2.70833333rem;"
				} else if (id == 2) {
					switchBar.style.cssText = "left: 3.6875rem;"
				} else if (id == 3) {
					switchBar.style.cssText = "left: 4.6875rem;"
				} else if (id == 4) {
					switchBar.style.cssText = "left: 5.66667rem;"
				} else if (id == 5) {
					switchBar.style.cssText = "left: 6.65625rem;"
				}
			},
			out() {
				var switchBar = document.querySelector(".switch")
				switchBar.style.cssText = "left: 2.70833333rem;"
			},
			changeNum(oldValue, value) {
				var zeroOne = document.querySelector(".zeroOne")
				var Sline = document.querySelector(".Sline")
				if (oldValue - value < 0 && oldValue - value > -6 || oldValue - value == 6) {
					if (oldValue == 0) {
						Sline.style.cssText = "left: 0.26041667rem;"
						zeroOne.innerText = "02"
					} else if (oldValue == 1) {
						Sline.style.cssText = "left: 0.52083rem;"
						zeroOne.innerText = "03"
					} else if (oldValue == 2) {
						Sline.style.cssText = "left: 0.78125rem;"
						zeroOne.innerText = "04"
					} else if (oldValue == 3) {
						Sline.style.cssText = "left: 1.041667rem;"
						zeroOne.innerText = "05"
					} else if (oldValue == 4) {
						Sline.style.cssText = "left: 1.302083rem;"
						zeroOne.innerText = "06"
					} else if (oldValue == 5) {
						Sline.style.cssText = "left: 1.588541667rem;"
						zeroOne.innerText = "07"
					} else if (oldValue == 6) {
						Sline.style.cssText = "left: 0;"
						zeroOne.innerText = "01"
					}
				} else if (oldValue - value > 0 && oldValue - value < 6 || oldValue - value == -6) {
					if (oldValue == 0) {
						Sline.style.cssText = "left: 1.588541667rem;"
						zeroOne.innerText = "07"
					} else if (oldValue == 1) {
						Sline.style.cssText = "left: 0;"
						zeroOne.innerText = "01"
					} else if (oldValue == 2) {
						Sline.style.cssText = "left: 0.26041667rem;"
						zeroOne.innerText = "02"
					} else if (oldValue == 3) {
						Sline.style.cssText = "left: 0.52083rem;"
						zeroOne.innerText = "03"
					} else if (oldValue == 4) {
						Sline.style.cssText = "left: 0.78125rem;"
						zeroOne.innerText = "04"
					} else if (oldValue == 5) {
						Sline.style.cssText = "left: 1.041667rem;"
						zeroOne.innerText = "05"
					} else if (oldValue == 6) {
						Sline.style.cssText = "left: 1.302083rem;"
						zeroOne.innerText = "06"
					}
				}
			},
			up() {
				var planList = document.querySelector(".planList")
				var plans = document.querySelectorAll(".plans")
				var up = document.querySelector(".up")
				var down1 = document.querySelector(".down1")
				if (this.transNum == 0) {
					return
				} else {
					down1.style.cssText = "opacity: 1; cursor: pointer;"
				}
				this.transNum = this.transNum + 161
				let StyleText = "transform: translateY(" + this.transNum + "px);"
				planList.style.cssText = StyleText
				if (this.transNum == 0) {
					up.style.cssText = "opacity: 0.7; cursor: default;"
				}
			},
			down() {
				var planList = document.querySelector(".planList")
				var up = document.querySelector(".up")
				var down1 = document.querySelector(".down1")
				if (this.transNum == -483) {
					return
				} else {
					up.style.cssText = "opacity: 1; cursor: pointer;"
				}
				this.transNum = this.transNum - 161
				let StyleText = "transform: translateY(" + this.transNum + "px);"
				planList.style.cssText = StyleText
				if (this.transNum == -483) {
					down1.style.cssText = "opacity: 0.7; cursor: default;"
				}
			},
			companyNews(id) {
				var culture = document.querySelector(".culture")
				var companyNew = document.querySelector(".companyNews")
				if (id == 'culture') {
					culture.style.cssText =
						"background: -webkit-linear-gradient(to right, #0067c7, #00c0ff);background: linear-gradient(to right, #0067c7, #00c0ff);color: white;"
					companyNew.style.cssText = "background: white;color: #585858;"
					this.isnews = false
				}
				if (id == 'news') {
					culture.style.cssText = "background: white;color: #585858;"
					companyNew.style.cssText =
						"background: -webkit-linear-gradient(to right, #0067c7, #00c0ff);background: linear-gradient(to right, #0067c7, #00c0ff);color: white;"
					this.isnews = true
				}
				this.getNewsList()
			},
			mousein(id) {
				var culture = document.querySelector(".culture")
				var companyNew = document.querySelector(".companyNews")
				if (id == 'culture' && this.isnews == false) {
					culture.style.cssText =
						"background: -webkit-linear-gradient(to right, #558EC4, #00c0ff);background: linear-gradient(to right, #558EC4, #00c0ff);color: white;"
				}
				if (id == 'culture' && this.isnews != false) {
					culture.style.cssText = "background: white;color: #0067C7;"
				}
				if (id == 'news' && this.isnews == true) {
					companyNew.style.cssText =
						"background: -webkit-linear-gradient(to right,  #558EC4, #00c0ff);background: linear-gradient(to right,  #558EC4, #00c0ff);color: white;"
				}
				if (id == 'news' && this.isnews != true) {
					companyNew.style.cssText = "background: white;color: #0067C7;"
				}
			},
			mouseou(id) {
				var culture = document.querySelector(".culture")
				var companyNew = document.querySelector(".companyNews")
				if (id == 'culture' && this.isnews == false) {
					culture.style.cssText =
						"background: -webkit-linear-gradient(to right, #0067c7, #00c0ff);background: linear-gradient(to right, #0067c7, #00c0ff);color: white;"
				}
				if (id == 'culture' && this.isnews != false) {
					culture.style.cssText = "background: white;color: #585858;"
				}
				if (id == 'news' && this.isnews == true) {
					companyNew.style.cssText =
						"background: -webkit-linear-gradient(to right, #0067c7, #00c0ff);background: linear-gradient(to right, #0067c7, #00c0ff);color: white;"
				}
				if (id == 'news' && this.isnews != true) {
					companyNew.style.cssText = "background: white;color: #585858;"
				}
			},
			openWindow() {
				this.showRecruit = true
			},
			closeWindow(data) {
				this.showRecruit = false
			}
		}
	}
</script>
<style lang="scss" scoped>
	.aboutTitle {
		font-size: 30px;
		color: #2E2E2E;
	}

	.subTitle {
		display: flex;
		align-items: center;
		padding-top: 12px;
		margin-bottom: 47px;

		>h4 {
			font-size: 20px;
			padding-left: 5px;
			color: #2E2E2E;
		}
	}

	.homeTitle {
		width: 1903px;

		.homeLink {
			top: 27px;
		}

		.switch {
			position: absolute;
			top: 0;
			height: 108px;
			transition-duration: 0.5s;
			left: 520px;
			// line-height: 208px;

			>img {
				position: absolute;
				bottom: 0;
				width: 84px;
				height: 3px;
			}
		}

		.line {
			top: 0;
			height: 107px;
			align-items: flex-end;

			>img {
				width: 1920px;
				height: 1px;
			}
		}

		.module {
			top: 131px;

			p {
				width: 190px;
				text-align: center;
				cursor: pointer;
				color: white;
			}
		}

		.theme {
			top: 349px;

			img {
				width: 920px;
				height: 123px;
			}
		}

		.ViewMore {
			top: 508px;

			P {
				line-height: 50px;
				width: 159px;
				height: 50px;
				text-align: center;
				background-color: white;
				color: black;
				border-radius: 25px;
				font-size: 16px;

				img {
					margin-bottom: 5px;
				}
			}

			P:hover {
				color: #0067C7;
			}

			p:active {
				color: #00396F;
			}
		}

		.scroll {
			top: 737px;
			color: white;
		}

		.down {
			top: 764px;
		}

		.public {
			width: 1886px;
			position: absolute;
			display: flex;
			justify-content: center;
			font-size: 16px;
		}

		::v-deep .ivu-carousel-dots {
			width: 50px;
			left: 1616px;
			top: 397px;

			>li {
				display: block;
				padding-left: 3px;

				>button {
					background: white;
					opacity: 0.5;
				}
			}

			.radius {
				color: white;
			}
		}

		::v-deep .ivu-carousel-active {
			padding-left: 0 !important;

			button {
				opacity: 1 !important;
				width: 12px !important;
				height: 12px !important;
			}
		}

		::v-deep .demo-carousel {
			img {
				width: 1903px;
				height: 901px;
			}
		}
	}

	.about {
		background-image: url(../../assets/img/home/关于旭普背景.png);
		background-size: 1920px 537px;
		background-repeat: no-repeat;
		width: 1903px;
		display: flex;
		justify-content: center;

		.aboutFont {
			margin-top: 100px;
			width: 694px;

			.font1 {
				font-size: 16px;
				line-height: 18px;
				color: #666666;
				padding-bottom: 18px;
			}

			.ViewMore {
				margin-top: 11px;
				margin-bottom: 40px;

				P {
					line-height: 44px;
					text-align: center;
					background-color: white;
					color: black;
					width: 159px;
					height: 44px;
					border: 1px solid #dedede;
					border-radius: 22px;
					font-size: 16px;

					img {
						margin-bottom: 4px;
					}
				}

				P:hover {
					color: #0067C7;
				}

				p:active {
					color: #00396F;
				}
			}
		}

		.aboutPic1 {
			margin-left: 40px;
			margin-top: 88px;
			height: 462px;

			>img {
				width: 455px;
				height: 462px;
			}
		}
	}

	.aboutPic2 {
		height: 277px;
		background-image: url(../../assets/img/home/业绩介绍.png);
		background-size: 1903px 277px;
		background-repeat: no-repeat;
		display: flex;
		align-items: center;
		color: white;

		.leftAbout {
			padding-left: 320px;

			// padding-top: 80px;
			.topAbout {
				width: 770px;
				display: flex;
				justify-content: space-around;
				align-items: center;

				.firstTopAbout {
					width: 180px;
					text-align: center;
					font-size: 14px;
				}

				.bigFont {
					font-size: 20px;
				}
			}

			.bottomAbout {
				padding-top: 25px;
			}
		}

		.rightAbout {
			width: 460px;
			text-align: center;

			p:first-of-type {
				font-size: 26px;
				padding-bottom: 5px;
			}

			p:last-of-type {
				font-size: 44px;

				img {
					width: 52px;
					height: 12px;
					cursor: pointer;
					animation: finger infinite 2s;
				}

				@keyframes finger {
					0% {
						transform: translate(-5px)
					}

					25% {
						transform: translate(5px)
					}

					50% {
						transform: translate(-5px)
					}

					75% {
						transform: translate(5px)
					}

					100% {
						transform: translate(-5px)
					}
				}
			}
		}
	}

	.solution {
		background-image: url(../../assets/img/home/解决方案背景.png);
		background-size: 1920px 920px;
		background-repeat: no-repeat;
		height: 920px;
		overflow: hidden;

		.solutionTI {
			width: 1903px;
			display: flex;

			.solutionTitle {
				padding-left: 359px;
				padding-top: 101px;
				width: 1200px;
			}
		}

		.switchLine {
			padding-top: 120px;
			margin-bottom: 50px;

			.zeroOne {
				font-size: 58px;
				color: #2E2E2E;
			}

			.zeroSix {
				position: relative;
				top: -20px;
				font-size: 28px;
				color: #959595;
				margin-right: 180px;
			}

			.Aline {
				height: 5px;
				width: 354px;
				background-color: #E4E4E4;
				border-radius: 3px;

				.Sline {
					position: relative;
					height: 5px;
					width: 50px;
					background-color: #009ADC;
					border-radius: 3px;
				}
			}
		}

		::v-deep .demo-carousel {
			img {
				width: 1632px;
				height: 572px;
			}
		}
	}

	.solutionPic {
		width: 1632px;
		height: 573px;
		margin: 0 auto;

		::v-deep .ivu-carousel-arrow.left {
			position: absolute;
			top: -80px;
			left: 1355px;
			color: #2E2E2E;
			width: 14px;
			height: 14px;
			background-color: transparent;

			>i {
				font-size: 14px;
			}
		}

		::v-deep .ivu-carousel-arrow.right {
			position: absolute;
			top: -80px;
			right: 225px;
			color: #2E2E2E;
			width: 14px;
			height: 14px;
			background-color: transparent;

			>i {
				font-size: 14px;
			}
		}

		.smartSpace {
			position: relative;
			top: -393px;
			left: 217px;
			color: white;
			width: 415px;

			h2 {
				font-size: 48px;
			}

			p {
				font-size: 20px;
				margin-bottom: 18px;
			}

			.button {
				font-size: 16px;
				letter-spacing: 2px;
				width: 214px;
				height: 50px;
				background: -webkit-linear-gradient(to right, #0067c7, #00c0ff);
				background: linear-gradient(to right, #0067c7, #00c0ff);
				line-height: 50px;
				text-align: center;
				border-radius: 25px;
				color: white;

				img {
					margin-bottom: 5px;
				}
			}

			.button:hover {
				color: white;
				background: -webkit-linear-gradient(to right, #558EC4, #6ADAFF);
				background: linear-gradient(to right, #558EC4, #6ADAFF);
			}

			.button:active {
				color: balck;
				background: -webkit-linear-gradient(45deg, #00509A, #008DBB);
				background: linear-gradient(45deg, #00509A, #008DBB);
			}
		}
	}

	.programme {
		position: relative;
		top: -600px;
		left: 1040px;
		height: 645px;
		// width: 370px;
		overflow: hidden;
		padding-left: 150px;

		.planList {
			height: 645px;
			// width: 370px;
			padding: 8px 0 0 10px;
			transform: translateY(0);
			transition-duration: 0.5s;

			.plans {
				width: 354px;
				height: 144px;
				background-color: white;
				margin-bottom: 18px;
				padding: 23px 23px 0 14px;
				border-radius: 2px;
				box-shadow: 0 0 10px #888888;

				>div {
					display: flex;
					align-items: center;
					font-size: 18px;
					color: #2E2E2E;
					font-weight: 600;
					line-height: 25px;
					padding-bottom: 4px;

					>img {
						margin-right: 14px;
					}
				}

				>p {
					font-size: 14px;
					color: #959595;
					line-height: 25px;
					padding-left: 31px;
				}

				span:hover {
					color: #0067C7;
				}

				span:active {
					color: #00396F;
				}
			}

			// .plans:hover {
			// 	transform: scale(1.2);
			// }
		}
	}

	.soluctionButton {
		width: 40px;
		position: absolute;
		top: 2461px;
		left: 1140px;

		.Sbutton {
			height: 40px;
			width: 40px;
			background-color: white;
			text-align: center;
			line-height: 40px;
			cursor: pointer;

			i {
				font-size: 14px;
			}
		}

		.Sbutton:hover {
			color: #009adc;
		}

		.Sbutton:active {
			color: #00396F;
		}

		.down1 {
			margin-top: 11px;
		}

		.up {
			opacity: 0.7;
			cursor: default;
		}
	}

	.newsView {
		background-image: url(../../assets/img/home/新闻资讯背景.png);
		background-size: 1920px 842px;
		background-repeat: no-repeat;
		width: 1903px;
		padding: 93px 0 0 359px;
		margin-bottom: 80px;

		.newsTitle {
			display: flex;
		}

		.newsCh {
			width: 391px;
			display: flex;
			justify-content: space-around;
			align-items: center;
			margin-left: 523px;

			.newsBUtton {
				width: 118px;
				height: 40px;
				background-color: white;
				cursor: pointer;
				color: #585858;
				border: 1px solid #DDDDDD;
				text-align: center;
				line-height: 40px;
				font-size: 16px;
			}

			.culture {
				color: white;
				background: -webkit-linear-gradient(to right, #0067c7, #00c0ff);
				background: linear-gradient(to right, #0067c7, #00c0ff);
			}

			.culture:hover {
				color: white;
				background: -webkit-linear-gradient(to right, #558EC4, #6ADAFF);
				background: linear-gradient(to right, #558EC4, #6ADAFF);
			}

			.newsBUtton:hover {
				color: #0067C7;
			}

			.newsBUtton:active {
				color: white !important;
				background: linear-gradient(45deg, #00509A, #008DBB) !important;
			}
		}

		.newsList {
			display: flex;
			margin-top: 24px;

			.newsPic1 {
				margin-right: 34px;

				img {
					width: 496px;
					height: 279px;
				}
			}

			.newsSimper {
				padding: 20px 37px 0 35px;
				background-color: #F8F8F8;
				width: 670px;
				height: 279px;

				.singleNews {
					display: flex;
					align-items: center;
					margin-bottom: 10px;

					span {
						font-size: 12px;
					}

					img {
						width: 11px;
						height: 11px;
						margin-right: 12px;
					}

					p {
						width: 520px;
						font-size: 16px;
						color: #262626;
						font-weight: 500;
					}

					p:hover {
						color: #0067C7;
					}

					p:active {
						color: #00396F;
					}
				}

				.readMore {
					margin-left: 456px;
				}
			}

			.readMore {
				width: 126px;
				height: 30px;
				background: -webkit-linear-gradient(to right, #0067c7, #00c0ff);
				background: linear-gradient(to right, #0067c7, #00c0ff);
				color: white;
				font-size: 10px;
				line-height: 30px;
				text-align: center;

				img {
					width: 12px;
					height: 8px;
					margin-left: 5px;
				}
			}

			.readMore:hover {
				color: white;
				background: -webkit-linear-gradient(to right, #558EC4, #6ADAFF);
				background: linear-gradient(to right, #558EC4, #6ADAFF);
			}

			.readMore:active {
				color: white !important;
				background: linear-gradient(45deg, #00509A, #008DBB) !important;
			}

			.ivu-carousel {
				width: 496px;
				height: 256px;
				margin-right: 34px;
				background-color: #F8F8F8;

				.firstNews {
					width: 496px;
					height: 256px;
					margin: 20px 37px 0 35px;

					.FNTitle {
						display: flex;

						h2 {
							color: #0073CF;
							font-size: 30px;
						}

						>p {
							padding: 16px 0 0 16px;
							font-size: 16px;
							color: #A0A0A0;
						}
					}

					h4 {
						width: 420px;
						font-size: 16px;
						color: #262626;
						line-height: 25px;
					}

					h4:hover {
						color: #0067C7;
					}

					h4:active {
						color: #00396F;
					}

					>p {
						width: 420px;
						padding-top: 11px;
						font-size: 14px;
						color: #000000;
						line-height: 25px;
						margin-bottom: 20px;
					}
				}

				::v-deep .ivu-carousel-dots {
					width: 425px;

					::v-deep .ivu-carousel-active {
						button {
							background: #0056D4 !important;
						}
					}
				}
			}

			.newsPic2 {
				cursor: pointer;

				img {
					width: 670px;
					height: 256px;
				}
			}

			.pic2Font {
				cursor: pointer;
				color: white;
				font-size: 16px;
				margin-top: -50px;
				padding-left: 37px;
			}

			.pic2Font:hover {
				color: #0067C7;
			}

			.pic2Font:active {
				color: #00396F;
			}
		}
	}

	.cooperation {
		background-image: url(../../assets/img/home/合作伙伴.png);
		background-size: 1920px 2.3rem;
		background-repeat: no-repeat;
		width: 1903px;
		padding: 85px 0 0 366px;

		.cooTitle {
			width: 370px;
			color: white;
			display: flex;
			font-size: 43px;
			justify-content: space-between;
			margin-bottom: 39px;
		}

		.cooFont {
			color: white;
			display: flex;
			padding-bottom: 90px;

			>p {
				padding-top: 10px;
				width: 370px;
				font-size: 16px;
			}

			.cooName {
				width: 910px;
				height: 103px;
				padding-left: 184px;
				display: flex;
				flex-wrap: wrap;
				align-items: baseline;

				img {
					margin-right: 79px;
				}

				img:first-of-type {
					width: 144px;
					height: 35px;
				}

				img:nth-of-type(2) {
					width: 143px;
					height: 39px;
				}

				img:nth-of-type(3) {
					width: 196px;
					height: 39px;
				}

				img:nth-of-type(4) {
					width: 149px;
					height: 32px;
				}

				img:nth-of-type(5) {
					width: 113px;
					height: 46px;
				}

				img:last-of-type {
					width: 146px;
					height: 41px;
					margin-left: 25px;
				}
			}
		}
	}
</style>
