<template>
	<div class="recruitBackGround">
		<div class="recruitWindow">
			<div class="recruitTitle">
				<div>
					<img src="../../assets/img/recruitmentInformation/warning-circle.png" alt="">
				</div>
				<h4>目前我们只支持邮箱投递简历和前程无忧招聘，请选择投递渠道：</h4>
				<div>
					<Icon type="md-close" @click="close"/>
					<!-- <img src="../../assets/img/recruitmentInformation/guanbi.png" alt="" @click="close"> -->
				</div>
			</div>
			<div class="recruitWay jumpOut">
				<p>渠道1:点击跳转前程无忧网站</p>
				<a href="https://jobs.51job.com/all/co183228.html">
					<div>
						<img src="../../assets/img/recruitmentInformation/图层 1952.png" alt="">
					</div>
				</a>
			</div>
			<div class="recruitWay">
				<p>渠道2:发送简历到旭普邮箱</p>
				<div class="recruitMail">
					<div>
						<img src="../../assets/img/recruitmentInformation/youxiang.png" alt="">
					</div>
					<p>supersit@supersit.com</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'recruit',
		methods:{
			close(){
				this.$emit('close',this.showRecruit = false)
			}
		}
	}
</script>

<style lang="scss" scoped>
	.recruitBackGround {
		width: 100vw;
		height: 100vh;
		position: fixed;
		top: 0;
		left: 0;
		overflow: hidden;
		background-color: rgba(0, 0, 0, 0.5);

		.recruitWindow {
			width: 600px;
			height: 400px;
			margin: 28vh auto;
			background-color: white;

			.recruitTitle {
				display: flex;
				padding: 15px 0 16px 26px;
				align-items: center;

				>div:first-of-type {
					img {
						width: 21px;
						height: 21px;
					}
				}

				h4 {
					padding: 0 35px 0 16px;
					font-size: 16px;
					color: #000000;
				}

				>div:last-of-type {
					height: 24px;
					width: 24px;
					display: flex;
					justify-content: center;
					align-items: center;
					i{
						font-size: 18px;
					}
					i:hover{
						cursor: pointer;
						color: #0067C7;
					}
				}
			}

			.recruitWay {
				>p {
					font-size: 12px;
					padding: 0 0 13px 47px;
				}

				>div {
					width: 524px;
					height: 111px;
					background-color: #ECF6FF;
					text-align: center;
					margin: 0 auto;

					
				}

				.recruitMail {
					display: flex;
					justify-content: center;

					>div {
						width: 38px;
						height: 36px;
						margin-top: 40px;
						img{
							width: 38px;
							height: 36px;
						}
					}

					p {
						font-size: 22px;
						color: #0067C7;
						margin-left: 12px;
						margin-top: 40px;
					}
				}
			}

			.jumpOut {
				margin-bottom: 14px;
				a {
					>div{
						width: 524px;
						height: 111px;
						background-color: #ECF6FF;
						text-align: center;
						margin: 0 auto;
					}
					img {
						width: 158px;
						height: 66px;
						margin-top: 22px;
					}
				}

				div:hover {
					color: white;
					background: linear-gradient(45deg, #558EC4, #6ADAFF);
				}
				div:active {
					color: white;
					background: linear-gradient(to right, #0067c7, #00c0ff);
				}
			}
		}
	}
</style>
