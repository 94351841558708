import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home/Home.vue'

Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'Home',
		component: Home
	},
	{
		path: '/about',
		name: 'About',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import( /* webpackChunkName: "about" */ '../views/about/aboutXuPu.vue')
	},
	{
		path: '/news',
		name: 'news',
		component: () => import('../views/news/newsPage.vue'),
		children: [{
			path: 'newsDetail',
			name: 'newsDetail',
			component: () => import('../views/news/newsDetail.vue')
		}]
	},
	{
		path: '/products',
		name: 'products',
		component: () => import('../views/ProductsServices/products.vue'),
		children: [{
			path: 'solutionDetail',
			name: 'solutionDetail',
			component: () => import('../views/ProductsServices/solutionDetail.vue')
		}]
	}
]

const router = new VueRouter({
	routes
})

// 路由守卫，登录过的去下一页，没登录的
// router.beforeEach((to,from,next)=>{
// 	if(store.state.token){
// 		if(to.path=='/login'){
// 			next('/')
// 		}else{
// 			next()
// 		}
// 	}else{
// 		if(to.path=='/login'){
// 			next()
// 		}else{
// 			next(`/login?redirect=${to.path}`)
// 		}
// 	}
// 	console.log(to)
// 	document.title = to.meta.title
// })


export default router
