
let url = process.env.NODE_ENV === 'production' ? 'https://api.supersit.com/' : "https://api.supersit.com/"
// "http://192.168.5.150:8080"

//定义接口文件
export default {
	url: url,
	newsList: url + '/api/news/list',
	newsDetail: url + '/api/news/',
	productsList: url + '/api/product/list',
	solutionDetail: url + '/api/product/solution/'
}