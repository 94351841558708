<template>
	<div class="publicBottom">
		<div class="bottomLink">
			<div class="aboutUs">
				<h4 class="boTitle">关于我们</h4>
				<p>广东旭普空间信息技术产业发展有限公司——以客户为中心，为客户创造出价值。</p>
				<div class="contact">
					<div>
						<Icon type="ios-call" />
						<p>广东旭普：020-87220925 ； 13510992928</p>
					</div>
					<div>
						<p class="wuhan">武汉旭普：027-81297090 ； 18186214583</p>
					</div>
					<div>
						<Icon type="ios-pin" />
						<p>广州市天河区高普路38号金发科技园五栋一楼</p>
					</div>
					<div>
						<Icon type="ios-pin" class="iconPoition"/>
						<p>武汉市东湖新技术区武大园路武大科技园慧业楼5048</p>
					</div>
					<!-- <div>
						<Icon type="md-mail" />
						<p>supersit@supersit.com</p>
					</div>
					<div class="wuhan">武汉旭普：http://www.3sidc.com/</div> -->
				</div>
			</div>
			<div class="serviceLink">
				<h4 class="boTitle">服务链接</h4>
				<div class="PageLink">
					<p>
						<router-link :to="{name:'Home'}">
							<span @click="changePage(1)">首页</span>
						</router-link>
					</p>
					<p>
						<router-link :to="{name:'About'}">
							<span @click="changePage(4)">集团简介</span>
						</router-link>
					</p>
					<p>
						<router-link :to="{name:'products'}">
							<span @click="changePage(2)">产品与服务</span>
						</router-link>
					</p>
					<p>
						<router-link :to="{name:'news'}">
							<span @click="changePage(3)">成功案例</span>
						</router-link>
					</p>
					<p @click="open">招聘信息</p>
					<p>
						<router-link :to="{name:'About'}">
							<span @click="changePage(4)">联系我们</span>
						</router-link>
					</p>
					<p>
						<a href="http://mail.supersit.com/">
							旭普邮箱
						</a>
					</p>
				</div>
			</div>
			<div class="friendLink">
				<h4 class="boTitle">友情链接</h4>
				<div class="outLInk">
					<p>
						<a href="http://www.mnr.gov.cn/">
							自然资源部
						</a>
					</p>
					<p>
						<a href="http://www.mwr.gov.cn/">
							水利部
						</a>
					</p>
					<p>
						<a href="http://www.mee.gov.cn/">
							生态环境部
						</a>
					</p>
					<p>
						<a href="http://www.cagis.org.cn/">
							中国地理信息产业协会
						</a>
					</p>
					<p>
						<a href="https://www.saac.gov.cn/">
							国家档案局
						</a>
					</p>
					<p>
						<a href="http://www.gdsia.org.cn/">
							广东行业软件协会
						</a>
					</p>
					<p>
						<a href="https://www.geoscene.cn/">
							ESRI中国(北京)有限公司
						</a>
					</p>
				</div>
			</div>
		</div>
		<div class="bottomLogo">
			<div class="bottomLine">
				<img src="../../assets/img/home/隔线.png" alt="">
			</div>
			<div class="permit">
				<p>广东旭普空间信息技术产业发展有限公司 粤ICP备20012179号</p>
				<div>
					<img src="../../assets/img/home/weixin.png" alt="" @mouseover="showWeiXin" @mouseout="closeWeiXin">
					<Icon type="logo-facebook" />
					<Icon type="logo-twitter" />
					<Icon type="logo-googleplus" />
				</div>
			</div>
		</div>
		<div v-show="weixinShow" class="weixin">
			<img src="../../assets/img/home/二维码.png" alt="">
		</div>
	</div>
</template>

<script>
	export default {
		name: 'bottom',
		data() {
			return {
				weixinShow: false,
			}
		},
		methods: {
			open() {
				this.$emit('open', this.showRecruit = true)
			},
			showWeiXin() {
				this.weixinShow = true
			},
			closeWeiXin() {
				this.weixinShow = false
			},
			changePage(id) {
				this.$emit('pageid', id)
			}
		}
	}
</script>

<style lang="scss" scoped>
	.publicBottom {
		background-image: url(../../assets/img/home/页尾背景.png);
		background-size: 1920px 470px;
		background-repeat: no-repeat;
		background-color: #1d2127;
		width: 1903px;
		// height: 470px;
		color: white;

		.bottomLink {
			display: flex;
			padding: 55px 0 0 423px;

			.boTitle {
				font-size: 20px;
				margin-bottom: 35px;
			}

			.aboutUs {
				width: 309px;
				margin-right: 51px;
				font-size: 14px;

				.contact {
					margin-top: 39px;

					>div {
						display: flex;
						align-items: center;
						line-height: 24px;

						i {
							margin-right: 12px;
						}
					}

					.wuhan {
						padding-left: 28px;
					}
				}
			}

			.serviceLink {
				width: 281px;

				.PageLink {
					display: flex;
					justify-content: space-between;
					flex-wrap: wrap;

					p {
						margin-bottom: 22px;
						margin-right: 76px;
						font-size: 14px;

						a {
							color: white;
							font-size: 14px;
						}
					}

					a:hover {
						color: #0067C7;
					}

					a:active {
						color: #00396F;
					}

					p:hover {
						cursor: pointer;
						color: #0067C7;
					}

					p:active {
						color: #00396F;
					}
				}
			}

			.friendLink {
				width: 500px;
				margin-left: 61px;

				.outLInk {
					display: flex;
					flex-wrap: wrap;

					p {
						margin-bottom: 22px;
						margin-right: 140px;
						font-size: 14px;

						a {
							color: white;
							font-size: 14px;
							text-indent: none;
						}
					}

					a:hover {
						color: #0067C7;
					}

					a:active {
						color: #00396F;
					}

					p:hover {
						cursor: pointer;
						color: #0067C7;
					}

					p:active {
						color: #00396F;
					}
				}
			}
		}

		.bottomLogo {
			background-color: #1d2127;
			.bottomLine {
				margin-top: 20px;
				opacity: 0.2;

				img {
					width: 1903px;
					height: 1px;
				}
			}

			.permit {
				width: 1480px;
				padding: 20px 0 20px 407px;
				font-size: 14px;
				color: white;
				display: flex;
				justify-content: space-between;

				p {
					opacity: 0.8;
				}

				i {
					opacity: 0.8;
					margin-right: 16px;
				}

				div {
					display: flex;
					align-items: center;
				}

				img {
					width: 17px;
					height: 14px;
					margin-right: 16px;
				}
			}
		}

		.weixin {
			position: fixed;
			right: 475px;
			bottom: 40px;

			img {
				width: 128px;
				height: 154px;
			}
		}
		.iconPoition{
			 margin-bottom: 20px;
		}
	}
</style>
